<template>
    <div class="page">
        <h1>404</h1>
<!--        <div class="LogoImg_Top">-->
<!--            <img src="../assets/head/logo.png" width="100px" alt="">-->
<!--        </div>-->
<!--        <h1 style="text-align: center;" class="logoFont" >找不到页面</h1>-->
<!--        <div class="LogoImg">-->
<!--            <img src="../assets/head/logo.png" width="100px" alt="">-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "notFount",
        data(){
            return{
            }
        },
        created() {

        },
        mounted() {

        }
    }
</script>

<style scoped>
    .page{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        /*background: linear-gradient(360deg, rgba(173, 228, 238, 0.81) 0%, rgb(52, 183, 253) 100%);*/
        color: blue;
        user-select: none;
    }
    .logoFont{
        background: linear-gradient(360deg, #4444e2,#409eff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .LogoImg:hover{
        pointer-events: none;
    }
    .LogoImg{
        padding: 10px;
        animation: run 5s infinite linear;
    }
    .LogoImg>img{
        border-radius: 50%;
    }
    @keyframes run {
        0% {
            transform: translatex(0px)  rotateZ(0deg);
        }
        25% {
            transform: translatex(150px)  rotateZ(180deg);
        }
        50% {
           transform: translateX(0px) rotateZ(0deg);
        }
        75% {
            transform: translateX(-150px) rotateZ(-180deg);
        }
        100% {
            transform: translateX(0px) rotateZ(0deg);
        }
    }
    .LogoImg_Top:hover{
        pointer-events: none;
    }
    .LogoImg_Top{
        padding: 10px;
        animation: run_Top 5s infinite linear;
    }
    .LogoImg_Top>img{
        border-radius: 50%;
    }
    @keyframes run_Top {
        0% {
            transform: translatex(0px)  rotateZ(0deg);
        }
        25% {
            transform: translatex(-150px)  rotateZ(-180deg);
        }
        50% {
            transform: translateX(0px) rotateZ(0deg);
        }
        75% {
            transform: translateX(150px) rotateZ(180deg);
        }
        100% {
            transform: translateX(0px) rotateZ(0deg);
        }
    }

    h1{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size:100px;
        animation:colorRun linear 5s infinite;
        color: transparent;
    }
    @keyframes colorRun {
        0%{
            color: gray;
            top: 0%;
            left: 0%;
        }
        25%{
            color: rgba(128, 128, 128, 0.5);
            top: 50%;
            left: 25%;
        }
        50%{
            color: black;
            top:25%;
            left: 50%;
        }
        75%{
            color: rgba(128, 128, 128, 0.5);
            top:50%;
            left: 75%;
        }
        100%{
            color: gray;
            top:0%;
            left: 100%;
        }
    }

</style>
